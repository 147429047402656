import React from 'react'
import "./HomePage.css"



// components


import Landing from './Components/Landing/Landing';
import Description from './Components/Description/Description';
import Mission from './Components/Mission/Mission';
import Values from './Components/Values/Values';
import Services from './Components/Services/Services';
import Testimonials from './Components/Testimonials/Testimonials';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';


const HomePage = () => {





    return (
        <div>


                <Landing />


                <Description/>

                <Mission />

                <Values />

                <Services />

                <Testimonials />

                <Contact />


                <Footer />

        </div>
    )
}

export default HomePage
